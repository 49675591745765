body {
  margin: 0;
  font-family: 'Quicksand', sans-serif,
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Quicksand', sans-serif,
    monospace;
}
